import gql from "graphql-tag";

export const DEACTIVATE_CLIENT = gql`
  mutation DeactivateClient($id: uuid!) {
    update_clients_by_pk(pk_columns: { id: $id }, _set: { active: false }) {
      id
    }
  }
`;
export const DEACTIVATE_LOCATION = gql`
  mutation DeactivateLocation($clientID: uuid!, $status: String) {
    update_clientLocations(where: { clientID: { _eq: $clientID } }, _set: { status: $status }) {
      affected_rows
    }
  }
`;

export const ACTIVATE_CLIENT = gql`
  mutation ActivateClient($id: uuid!) {
    update_clients_by_pk(pk_columns: { id: $id }, _set: { active: true }) {
      id
    }
  }
`;

export const UPDATE_CLIENT_SETUP_BY_PK = gql`
  mutation UpdateClientSetup($clientID: uuid!, $_set: clients_set_input) {
    update_clients_by_pk(pk_columns: { id: $clientID }, _set: $_set) {
      addressID
    }
  }
`;

export const UPDATE_ADDRESS_BY_PK = gql`
  mutation MyMutation($addressID: uuid!, $_set: addresses_set_input) {
    update_addresses_by_pk(pk_columns: { id: $addressID }, _set: $_set) {
      id
    }
  }
`;

export const UPDATE_RECRUITMENT_TIMEPERIOD = gql`
  mutation MyMutation($id: uuid!, $startDay: String, $endDay: String, $timeZone: String) {
    update_timePeriod_by_pk(pk_columns: { id: $id }, _set: { startDay: $startDay, endDay: $endDay, timezone: $timeZone }) {
      id
    }
  }
`;

export const UPDATE_CLIENT_MANAGERS = gql`
  mutation updateClientManagers($clientID: uuid!, $managerIDs: [uuid!]!, $managers: [clientManagers_insert_input!]!) {
    delete_clientManagers(where: { _and: [{ clientID: { _eq: $clientID } }, { managerID: { _nin: $managerIDs } }] }) {
      affected_rows
    }
    insert: insert_clientManagers(objects: $managers, on_conflict: { constraint: clientManagers_clientID_managerID_key, update_columns: [] }) {
      affected_rows
      returning {
        clientID
      }
    }
  }
`;

export const ATTACH_EMPTY_ADDRESS = gql`
  mutation attachEmptyAddress {
    insert_addresses_one(object: { address1: " " }) {
      id
    }
  }
`;
