<template>
  <add-edit-page>
    <template #header="{ validation }">
      <sticky-header title="Client Edit Setup">
        <h4>{{ client.name }}</h4>
        <save-cancel :save-enabled="!validation.pristine && !validation.invalid" @onSave="handleSubmit" />
      </sticky-header>
    </template>
    <client-form v-model="client" :managers="managers" :selected-managers="selectedManagers" @clientManagerChanged="updateClientManager" />
    <template #bottomContent="{ validation }">
      <save-cancel :save-enabled="!validation.pristine && !validation.invalid" on-can @onSave="handleSubmit" />
    </template>
  </add-edit-page>
</template>

<script>
import { FIND_ALL_MANAGERS, GET_CLIENT_DETAILS_BY_PK } from "@/modules/admin/clients/graph/queries";
import { UPDATE_ADDRESS_BY_PK, UPDATE_CLIENT_MANAGERS, UPDATE_CLIENT_SETUP_BY_PK, UPDATE_RECRUITMENT_TIMEPERIOD } from "@/modules/admin/clients/setup/mutations";
import { AddEditPage, SaveCancel, StickyHeader } from "@/components";
import ClientForm from "@/modules/admin/clients/components/ClientForm";
import { mapGetters } from "vuex";
export default {
  name: "ClientEditSetup",
  components: { ClientForm, AddEditPage, StickyHeader, SaveCancel },
  data() {
    return {
      client: {
        address: {
          data: { state: "", country: "US" },
        },
        mvrPackageName: "",
        screeningPackageName: "",
        startDay: "",
        endDay: "",
      },
      managers: [],
      selectedManagers: [],
    };
  },
  computed: {
    ...mapGetters(["getFullName"]),
  },
  methods: {
    handleSubmit() {
      this.updateClientSetup();
    },
    updateClientSetup() {
      const clientID = this.$route.params.id;
      let clientConfiguration = {};
      if (this.client.mvrPackageName && this.client.mvrPackageName.length > 0) {
        clientConfiguration.mvrPackageName = this.client.mvrPackageName;
      }
      if (this.client.screeningPackageName && this.client.screeningPackageName.length > 0) {
        clientConfiguration.screeningPackageName = this.client.screeningPackageName;
      }

      this.$apollo
        .mutate({
          mutation: UPDATE_CLIENT_SETUP_BY_PK,
          variables: {
            clientID,
            _set: {
              ageRequirement: this.client.ageRequirement,
              federalTaxID: this.client.federalTaxID,
              isRecruiting: this.client.isRecruiting,
              name: this.client.name,
              phone: this.client.phone,
              startDate: this.client.startDate,
              taxProgram: this.client.taxProgram,
              timeZone: this.client.timeZone,
              clientConfiguration: { ...this.client.clientConfiguration, ...clientConfiguration },
            },
          },
        })
        .then(async ({ data }) => {
          this.updateAddress(data.update_clients_by_pk.addressID);
          await this.updateTimePeriod(this.client.timePeriods[0].id);
          const clientManagers = this.selectedManagers.map((manager) => ({ clientID, managerID: manager.id, assignedBy: this.getFullName }));
          const managerIDs = clientManagers.map((manager) => manager.managerID);
          return this.$apollo.mutate({ mutation: UPDATE_CLIENT_MANAGERS, variables: { managers: clientManagers, clientID, managerIDs } });
        })
        .finally(() => this.$router.push({ name: "client", hash: "#clientSetup", params: { id: clientID } }));
    },
    updateAddress(id) {
      this.$apollo.mutate({
        mutation: UPDATE_ADDRESS_BY_PK,
        variables: {
          addressID: id,
          _set: {
            address1: this.client.address.data.address1,
            address2: this.client.address.data.address2,
            city: this.client.address.data.city,
            country: this.client.address.data.country,
            postalCode: this.client.address.data.postalCode,
            state: this.client.address.data.state,
          },
        },
      });
    },
    updateTimePeriod(id) {
      return this.$apollo.mutate({
        mutation: UPDATE_RECRUITMENT_TIMEPERIOD,
        variables: {
          id: id,
          startDay: this.client.startDay,
          endDay: this.client.endDay,
          timeZone: this.client.timeZone,
        },
      });
    },
    updateClientManager(itemChangedEvent) {
      this.selectedManagers = itemChangedEvent.items;
    },
  },
  apollo: {
    client: {
      query: GET_CLIENT_DETAILS_BY_PK,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      result({ data }) {
        const { departmentManagers, ...clientDetail } = data.client;
        this.client = clientDetail;
        this.client.address.data = this.client.address;
        this.client.mvrPackageName = this.client.clientConfiguration.mvrPackageName;
        this.client.screeningPackageName = this.client.clientConfiguration.screeningPackageName;
        this.client.startDay = this.client.timePeriods[0].startDay;
        this.client.endDay = this.client.timePeriods[0].endDay;
        this.selectedManagers = departmentManagers
          .flatMap((departmentManager) => departmentManager.manager)
          .map((manager) => ({
            id: manager.id,
            name: `${manager.firstName} ${manager.lastName}`.trim(),
            role: manager.user.role,
            userID: manager.user.id,
          }));
      },
      skip() {
        return !this.$route.params.id;
      },
    },
    managers: {
      query: FIND_ALL_MANAGERS,
      update: (data) => data.employees.map((employee) => ({ id: employee.id, name: `${employee.firstName} ${employee.lastName}`.trim(), role: employee.user.role, userID: employee.user.id })),
    },
  },
};
</script>
